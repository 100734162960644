:root {
    --border-color: #dcdcdc;
    --border-color-2: hsl(0, 0%, 81%);
    --border-color-3: #e9e9e9;
    --button-color: rgb(201, 198, 198);
    --sub-color: #363636;
    --color: black;
    --color-2: rgb(255, 255, 255);
    --color-3: rgb(145, 145, 145);
    --color-4: rgb(44, 44, 44);
    --color-5: rgb(44, 44, 44);
    --hover-color: rgb(248, 248, 248);
    --hover-color-2: rgb(238, 238, 238);
    --hover-color-3: rgb(228, 228, 228);
    --hover-color-4: rgb(218, 218, 218);
    --image-bg-color: rgb(230, 230, 230);
    --bg-color: rgb(252, 252, 252);
    --bg-color-2: rgb(252, 252, 252);
    --bg-color-3: rgb(240, 240, 240);
    --bg-color-4: rgb(245, 245, 245);
    --bg-color-5: rgb(220, 220, 220);
    --bg-color-6: rgb(192, 192, 192);
    --active-color: #e8f2ff;
    --active-color-2: #b1b1b1;
    --active-color-3: #bbbbbb;
    --active-color-4: #7eb4fb;
    --header-bgcolor: rgba(255, 255, 255, 0.8);
    --sub-header-bgcolor: #f5f5f5;
    --sub-header-bgcolor-2: #d2d2d2;
}

#root{
    display: flex ;
    flex-direction: column;
    min-height: 100vh;
}
main{
    flex: 1;
}
body {
    margin: 0;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    background-color: white;
    background-image: url('/src/assets/image/view/pngegg.png');
    /* 사용자 정의 화살표 이미지 */
    background-repeat: no-repeat;
    background-position: right 3px center;
    background-size: 16px;
    /* 화살표 이미지 크기 */
    border: 1px solid #cccccc;
    box-sizing: border-box;
    font-size: var(--font-size-0-9);
    padding: 8px 30px 8px 18px;
    border-radius: 3px;
}

input {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #cccccc;
}


/* 슬라이더 트랙 및 핸들 스타일링 */
input[type="range"] {
    -webkit-appearance: none;
    /* 기본 브라우저 스타일 제거 */
    width: 100%;
    height: 30px;
    /* 트랙 두께 */
    background: transparent;
    /* 슬라이더 트랙 배경을 투명으로 설정 */
    outline: none;
    /* 기본 외곽선 제거 */
    position: relative;
}

input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 15px;
    /* 트랙 두께 */
    background: #f0f0f0;
    /* 트랙 색상 (검정색) */
    border-radius: 2px;
    /* 트랙 모서리 둥글게 */
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    /* 기본 브라우저 핸들 스타일 제거 */
    appearance: none;
    width: 15px;
    height: 15px;
    background: #000;
    /* 핸들의 배경색 (검정색) */
    cursor: pointer;
    /* 핸들을 클릭할 때 포인터 모양으로 변경 */
    border-radius: 4px;
    /* 핸들을 둥글게 (약간 둥글게) */
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    /* 핸들의 그림자 */
    position: relative;
    z-index: 3;
}

input[type="range"]::-moz-range-track {
    width: 100%;
    height: 6px;
    /* 트랙 두께 */
    background: #000;
    /* 트랙 색상 (검정색) */
    border-radius: 4px;
    /* 트랙 모서리 둥글게 */
}

* {
    list-style: none;
    text-decoration: none;
    padding: 0;
    margin: 0;
    font-family: 'Gothic A1', sans-serif !important;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
label,
a {
    transform: rotate(0.04deg);
    /* 텍스트 계단현상 방지 */
}

.not-rotate * {
    transform: rotate(0deg) !important;
}


a {
    text-decoration: none;
    color: var(--color);
}

hr {
    margin: 10px 0;
    background-color: var(--border-color-2);
}

.page-title {
    font-size: var(--font-size-1-4);
    margin: 20px 5px;
    font-weight: 600;
}

.large-font {
    font-size: var(--font-size-1-2);
}

.main-div {
    max-width: 1400px;
    width: 96vw;
    margin: 0 auto;
}



.kakao-inquiry {
    width: 200px;
}

.item-align-right {
    display: flex;
    align-items: center;
    justify-content: end;
}

span[id$='message'] {
    font-size: var(--font-size-0-8);
}

.empty-view {
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    box-shadow: inset 0 0 2px 1px var(--border-color);
    font-size: var(--font-size-1-1);
    padding: 0 10px;
    text-align: center;
}

/* ------------------------------ info 테이블 ------------------------------- */
.info-table span {
    padding: 0;
    font-size: var(--font-size-0-7);
    padding-left: 5px;
    color: rgb(62, 62, 255);
}

.info-table {
    border-top: 2px solid black;
    max-width: 100%;
    border-collapse: collapse;
    text-align: left;
    margin: 0px auto 30px;
    width: 100%;
    font-size: var(--font-size-0-8);
}

.info-table th {
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 17px;
    border-collapse: collapse;
    background-color: var(--bg-color);
}

.info-table .close-left {
    border-left: 1px solid var(--border-color);
}

.info-table td {
    padding: 12px;
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    text-align: left;
}

.info-table .center {
    text-align: center;
}

.info-table input {
    margin: 0 !important;
    padding: 7px 12px !important;
    font-size: var(--font-size-0-9) !important;
}

.info-table p {
    margin: 2px 0;

}

@media (max-width: 768px) {
    .info-table {
        max-width: 96vw;
    }

    .info-table th {
        padding: 10px;
    }

    .info-table td {
        padding: 10px;
    }
}

@media (max-width: 480px) {
    .info-table {
        max-width: 96vw;
    }

    .info-table th {
        padding: 7px;
    }

    .info-table td {
        padding: 7px;
    }
}


/* ------------------------------ 스크롤 ------------------------------- */

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-track {
    background-color: #f0f0f0;
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 10px rgb(200, 200, 200);
}

/* ------------------------------ 폰트 ------------------------------- */

.gothic-a1-thin {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 100;
    font-style: normal;
}

.gothic-a1-extralight {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 200;
    font-style: normal;
}

.gothic-a1-light {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 300;
    font-style: normal;
}

.gothic-a1-regular {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 400;
    font-style: normal;
}

.gothic-a1-medium {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 500;
    font-style: normal;
}

.gothic-a1-semibold {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 600;
    font-style: normal;
}

.gothic-a1-bold {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 700;
    font-style: normal;
}

.gothic-a1-extrabold {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 800;
    font-style: normal;
}

.gothic-a1-black {
    font-family: 'Gothic A1', sans-serif;
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 100;
    src: url('/src/assets/font/GothicA1-Thin.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 200;
    src: url('/src/assets/font/GothicA1-ExtraLight.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 300;
    src: url('/src/assets/font/GothicA1-Light.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 400;
    src: url('/src/assets/font/GothicA1-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 500;
    src: url('/src/assets/font/GothicA1-Medium.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 600;
    src: url('/src/assets/font/GothicA1-SemiBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 700;
    src: url('/src/assets/font/GothicA1-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 800;
    src: url('/src/assets/font/GothicA1-ExtraBold.ttf') format('truetype');
}

@font-face {
    font-family: 'Gothic A1';
    font-weight: 900;
    src: url('/src/assets/font/GothicA1-ExtraBold.ttf') format('truetype');
}

/* ------------------------------ 아이콘 ------------------------------- */

i::before {
    margin: 0 !important;
}

.icon-star,
.icon-star-empty {
    color: rgb(255, 187, 0);
    font-size: var(--font-size-0-8);
}

.icon-heart,
.icon-heart-empty {
    color: rgb(255 68 0 / 64%) !important;
}
