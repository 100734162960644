
.price {
    font-size: var(--font-size-1);
    max-width: 700px;
    width: 100%;
    margin: 0 auto;
    background-color: white;
    display: block !important;


    .info {
        position: sticky ;
        box-shadow: inset 0 0 2px 1px var(--border-color);
        top: 120px;
        color: var(--color-3);
        font-weight: 600;
        display: flex;
        flex-direction: column;
        margin: 5px 0;

        div {
            display: flex;
            justify-content: space-between;
            margin: 0 20px;
            padding: 12px 0;
        }

        div:has(input,select){
            padding:  0 !important;
            margin: 0 12px;
        }

        div:nth-child(4) {
            border-top: 1px solid var(--border-color);
        }

        .buttonPlace {
            justify-content: end;
            gap: 8px;

            input {
                font-size: var(--font-size-0-8);
                padding: 8px 12px;
            }
        }

        &>input{
            width: auto;
            margin:  0 12px 10px ;
            box-sizing: border-box;

        }

        p{
            padding: 0;
            span{
                font-size: var(--font-size-0-7);
                color: var(--active-color-4);
            }
        }
    }
}

@media (max-width: 768px) {
    .price {
        bottom: 0;
        position: sticky;
    }
}
